import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="A" transform="translate(33.000000, 7.000000)">
        <text
          id="A"
          transform="translate(0 62)"
          fill="#29bc89"
          fontSize="50"
          fontFamily="Roboto-Medium, Roboto"
          fontWeight="500">
          <tspan x="0" y="0">
            A
          </tspan>
        </text>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
