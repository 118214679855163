import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    // display: grid;
    // grid-template-columns: repeat(2, minmax(140px, 200px));
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--red);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }
  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--white);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--red);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "Adi.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, traceSVG: { color: "#29bc89" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Languages: TypeScript, JavaScript, Python, GoLang, HTML, CSS, Dart, Java, WebAssembly',
    'Libraries/Frameworks: NextJS, ReactJS, NodeJS, Tensorflow, Jest, Flutter',
    'Databases: MySQL, MongoDB, Firebase',
    'Design: Adobe Photoshop, Illustrator, Xd, Figma',
    'Tools: Prisma, Kubernetes, Docker, Git, GCP, AWS, JIRA, Confluence, Apache Solr, Apache TomCat, GitHub Actions'
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">ನಮಸ್ಕಾರ</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I'm Adithya, a Software Engineer at Red Hat and a Maintainer
              of{' '}
              <a href="https://meshery.io" target="_blank" rel="noopener noreferrer">
                Meshery
              </a>
              , a CNCF Sandbox Project. I'm based out of Bengaluru, India
            </p>

            <p>
              I'm passionate about Frontend Development and Distributed Systems, with a keen interest in UI/UX Design, Full Stack Development, and contributing to Open Source projects.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            <li><strong style={{color: 'white'}}>Languages:</strong> TypeScript, JavaScript, Python, GoLang, HTML, CSS, Dart, Java, WebAssembly</li>
            <li><strong style={{color: 'white'}}>Libraries/Frameworks:</strong> ReactJS, NodeJS, Tensorflow, Jest, Flutter</li>
            <li><strong style={{color: 'white'}}>Databases:</strong> MySQL, MongoDB, Firebase</li>
            <li><strong style={{color: 'white'}}>Design:</strong> Adobe Photoshop, Illustrator, Xd, Figma</li>
            <li><strong style={{color: 'white'}}>Tools:</strong> Kubernetes, Docker, Git, GCP, AWS, JIRA, Confluence, Apache Solr, Apache TomCat, GitHub Actions</li>
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage 
              className="img"
              src="../../images/Adi.jpeg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot" />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
