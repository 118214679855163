import React from 'react';

const IconZap = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 512.001 512.001"
    enableBackground="new 0 0 512.001 512.001">
    <path d="m413.951,200.174c-2.828-5.047-8.164-8.174-13.953-8.174h-110.608l14.555-174.668c0.609-7.312-3.836-14.1-10.781-16.467-6.914-2.383-14.609,0.282-18.594,6.446l-175.998,271.997c-3.188,4.922-3.43,11.194-0.625,16.342 2.805,5.148 8.195,8.35 14.054,8.35h110.718l-14.672,190.773c-0.57,7.375 3.992,14.18 11.031,16.454 1.625,0.523 3.281,0.774 4.922,0.774 5.453,0 10.687-2.804 13.656-7.656l175.998-287.998c3.016-4.937 3.133-11.126 0.297-16.173zm-168.896,230.685l10.898-141.63c0.344-4.445-1.187-8.829-4.211-12.103-3.031-3.265-7.289-5.126-11.742-5.126h-98.585l125.335-193.707-10.695,128.382c-0.367,4.461 1.141,8.865 4.172,12.162 3.031,3.29 7.296,5.163 11.773,5.163h99.468l-126.413,206.859z" />
  </svg>
);

export default IconZap;
